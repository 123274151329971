import {
  FETCH_BUSINESSTRIPS_SUCCESS,
  FETCH_BUSINESSTRIPS_USER_SUCCESS,
  CREATE_BUSINESSTRIP,
  UPDATE_BUSINESSTRIP,
  DELETE_BUSINESSTRIP,
  SET_BUSINESSTRIP_ID,
} from "actions/types";
import { produce } from "immer";

const initialState = {
  BusinessTrips: [],
  businessTripId: -1,
  error: "",
};

const businessTripsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_BUSINESSTRIPS_SUCCESS:
        draft.BusinessTrips = action.payload;
        draft.error = "";
        break;
      case FETCH_BUSINESSTRIPS_USER_SUCCESS:
        draft.BusinessTrips = action.payload;
        draft.error = "";
        break;
      case CREATE_BUSINESSTRIP:
        draft.BusinessTrips = [action.payload, ...draft.BusinessTrips];
        draft.error = "";
        break;
      case UPDATE_BUSINESSTRIP:
        key = draft.BusinessTrips.findIndex(
          (CL) => CL.id === action.payload.id
        );
        draft.BusinessTrips[key] = action.payload;
        draft.businessTripId = -1;
        draft.error = "";
        break;
      case DELETE_BUSINESSTRIP:
        key = draft.BusinessTrips.findIndex(
          (CL) => CL.id === action.payload.id
        );
        draft.BusinessTrips.splice(key, 1);
        draft.businessTripId = -1;
        draft.error = "";
        break;
      case SET_BUSINESSTRIP_ID:
        draft.businessTripId = action.payload;
        break;
      default:
        return state;
    }
  });

export default businessTripsReducer;
