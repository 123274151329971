import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import { hasPermission } from "Components/Utilities";
import history from "../history";
import {
  createPersonalSettings,
  fetchCompanyLocations,
  fetchPersonalSettings,
  fetchPersonalDisplaySettings,
  fetchStandardDisplaySettings,
  fetchPersonsMinimal,
  setLoggedUser,
  setLocationId,
  fetchRolls,
  fetchDepartmentsMinimal,
  fetchUnpublishedDays,
  fetchTimes,
  fetchCurrentStats,
  fetchSomuraStart,
  saveAlive,
  dataReset,
} from "../redux/index";
import { DateTime, Settings } from "luxon";
import { jwtDecode } from "jwt-decode";
import { NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { languages } from "Constants";
import store from "../redux/store";
import { Box } from "@mui/material";
import { getGlobalSetting, getPersonalSetting } from "Components/Utilities";
import SomuraTooltip from "Components/SomuraTooltip";
import LocationSelection from "Components/LocationSelection";

import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import MonthCalIcon from "@mui/icons-material/TodayTwoTone";
import DateRangeIcon from "@mui/icons-material/DateRangeTwoTone";
// import EventBusyIcon from "@mui/icons-material/EventBusy";
import PersonsIcon from "@mui/icons-material/PeopleTwoTone";
import TimeTrackIcon from "@mui/icons-material/TimerOutlined";
import ProjectsIcon from "@mui/icons-material/AccountTreeOutlined";
import DevicesIcon from "@mui/icons-material/Devices";

import ReplayIcon from "@mui/icons-material/Replay";
import NewsBadge from "./NewsBadge.js";
import TimeFaultyBadge from "./TimeFaultyBadge.js";
import UnpublishedDaysBadge from "./UnpublishedDaysBadge.js";
import UnpublishedDays from "./UnpublishedDays";
import RemindersBadge from "./RemindersBadge.js";
import RemindersAlert from "./RemindersAlert.js";

import SettingsMenu from "./SettingsMenu";
import UserMenu from "./UserMenu";
import LanguageMenu from "./LanguageMenu";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Snackbar from "@mui/material/Snackbar";
import SomuraAlert from "Components/SomuraAlert";
import ShowTime from "./ShowTime";

import RemindersDialog from "Reminders/RemindersDialog";

const drawerWidth = 240;
const appBarHeight = 64;

const mapStateToProps = (state) => {
  return {
    dataError: state.general.dataError,
    dataSuccess: state.general.dataSuccess,
    dataLoading: state.general.dataLoading,
    actionSuccess: state.general.actionSuccess,
    error: state.general.error,
    loggedUser: state.loggeduser.LoggedUser,
    globalSettings: state.settings.GlobalSettings,
    unpublishedDays: state.unpublisheddays.UnpublishedDays,
    reminders: state.reminders.Reminders,
    showMessage: state.general.showMessage,
    selectedDepartmentId: state.selections.displayDepartmentId,
    showReminderDate: state.reminders.showReminderDate,
    selectedLocation: state.selections.selectedLocationId,
    stats: state.general.Stats,
    locations: state.company.Locations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPersonalSettings: (id) =>
      dispatch(createPersonalSettings({ userId: id })),
    setLoggedUser: (id) => dispatch(setLoggedUser({ id: id })),
    setLocationId: (values) => dispatch(setLocationId(values)),
    fetchRolls: (values) => dispatch(fetchRolls(values)),
    fetchCompanyLocations: async () => dispatch(fetchCompanyLocations()),
    fetchPersonalSettings: (id) =>
      dispatch(fetchPersonalSettings({ userId: id })),
    fetchPersonalDisplaySettings: (id) =>
      dispatch(fetchPersonalDisplaySettings({ userId: id })),
    fetchStandardDisplaySettings: () =>
      dispatch(fetchStandardDisplaySettings()),
    fetchPersonsMinimal: (values) => dispatch(fetchPersonsMinimal(values)),
    fetchDepartmentsMinimal: async () => dispatch(fetchDepartmentsMinimal()),
    fetchUnpublishedDays: (id) =>
      dispatch(fetchUnpublishedDays({ userId: id })),
    fetchTimes: async (values) => dispatch(fetchTimes(values)),
    fetchCurrentStats: async (values) => dispatch(fetchCurrentStats(values)),
    fetchSomuraStart: () => dispatch(fetchSomuraStart()),
    saveAlive: (values) => dispatch(saveAlive(values)),
    dataReset: () => dispatch(dataReset()),
  };
};

const MainMenu = ({
  loggedUser,
  reminders,
  fetchCurrentStats,
  fetchSomuraStart,
  ...props
}) => {
  const { t, i18n } = useTranslation();
  const selectedLocation = useSelector((state) => state.selections);
  const [loggedUserId, setLoggedUserId] = useState("");
  const [open, setOpen] = useState(false);
  const [openUnpublishedDays, setUnpublishedDays] = useState(false);
  const [disableMessages, setDisableMessages] = useState(false);
  const [useTimeTracking, setUseTimeTracking] = useState(false);
  const [useArticles, setUseArticles] = useState(false);
  const [alertReminders, setAlertReminders] = useState([]);
  const [todayReminders, setTodayReminders] = useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  // const myReminders = useRef(reminders);
  const timeRef = useRef();
  const today = DateTime.now();

  const multilingual =
    parseInt(getGlobalSetting("language", "multilingual")) === 1;
  const useWebTerminal =
    parseInt(getGlobalSetting("timetracking", "useWebTerminal")) === 1;
  const maxClientWidth = parseInt(
    getPersonalSetting("general", "maxClientWidth")
  );
  const clientPosition = parseInt(
    getPersonalSetting("general", "clientPosition")
  );
  let numUnpublished = 0;
  if (props.unpublishedDays.length > 0) {
    numUnpublished = props.unpublishedDays.reduce(function (prev, act) {
      return prev + parseFloat(act.numDays);
    }, 0);
  }

  // if (navigator.geolocation) {
  //   navigator.geolocation.getCurrentPosition(success, error);
  // } else {
  //   console.log("Geolocation not supported");
  // }

  // function success(position) {
  //   const latitude = position.coords.latitude;
  //   const longitude = position.coords.longitude;
  //   console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
  // }

  // function error() {
  //   console.log("Unable to retrieve your location");
  // }

  const menuItemClick = (name) => () => {
    store.dispatch({
      type: "DISABLE_LOCATION_SELECTION",
      payload: false,
    });
    store.dispatch({
      type: "OPEN_USER_STATS",
      payload: false,
    });
    store.dispatch({
      type: "SET_PERSON_ID",
      payload: -1,
    });
    localStorage.setItem("lastPage", name);
    setOpen(false);
    if (name === "Reminders") {
      store.dispatch({
        type: "SET_DISPLAY_REMINDER",
        payload: {
          reminders: reminders.filter(
            (R) =>
              DateTime.fromSQL(R.date)
                .startOf("day")
                .diff(DateTime.now().startOf("day"), "days")
                .toObject().days <= 0
          ),
        },
      });
      store.dispatch({
        type: "OPEN_REMINDER_DIALOG",
        payload: {
          calendar: "",
          date: DateTime.now().toSQLDate(),
          mode: "E",
        },
      });
    }
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleUDClose = () => {
    setUnpublishedDays(false);
  };

  const handleUDOpen = () => {
    setUnpublishedDays(true);
  };

  const handleSnackClose = () => {
    props.dataReset();
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleReloadClick = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    window.location.reload(true);
  };

  const updateData = () => {
    const token = localStorage.getItem("jwt");
    if (!token) return;
    const userid = jwtDecode(token).uid;

    if (selectedLocation !== undefined) {
      fetchCurrentStats({
        userId: userid,
        locationId: localStorage.getItem("location"),
      });
    }
    setTodayReminders(props.stats.numReminders);
    setAlertReminders(props.stats.numAlerts);
  };

  useEffect(() => {
    updateData();
    fetchSomuraStart();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Fetch the rolls
    props.fetchRolls({ locationId: props.selectedLocation });
    // eslint-disable-next-line
  }, [props.selectedLocation]);

  useEffect(() => {
    if (localStorage.jwt) {
      const token = localStorage.getItem("jwt");
      const tokenId = jwtDecode(token).uid;
      setLoggedUserId(tokenId);
    }
    // eslint-disable-next-line
  }, [localStorage.jwt]);

  useEffect(() => {
    if (props.globalSettings) {
      if (
        props.globalSettings["system"]?.settings["useMessageModule"] === "1"
      ) {
        updateData();
      }
    }
    setDisableMessages(
      props.globalSettings["system"]?.settings["useMessageModule"] !== "1"
    );
    setUseTimeTracking(
      useWebTerminal &&
        props.globalSettings["system"]?.settings["useTimeTrackModule"] === "1"
    );
    setUseArticles(
        props.globalSettings["system"]?.settings["useArticlesModule"] === "1"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.globalSettings]);

  useEffect(() => {
    // Get basic persons information
    if (loggedUser.companyId !== undefined) {
      if (loggedUser.changePW) {
        history.push("/changepw");
      }
      fetchCurrentStats({
        userId: loggedUser?.id,
        locationId: loggedUser?.companyId,
      });
      // Set already stored location ID
      props.setLocationId(loggedUser.companyId);
      localStorage.setItem("location", loggedUser.companyId);
      props.fetchPersonsMinimal({ companyId: loggedUser?.companyId });
      // Set the language of the user's company location
      const language = languages.find(
        (L) => parseInt(L.languageId) === parseInt(loggedUser.languageId)
      )?.languageName;
      i18n.changeLanguage(language);
      Settings.defaultLocale = language.substr(0, 2);
      localStorage.setItem("lang", language);
    }
    //eslint-disable-next-line
  }, [loggedUser]);

  useLayoutEffect(() => {
    // Set language
    const localLanguage = localStorage.getItem("lang");
    if (
      localLanguage &&
      localLanguage !== "undefined" &&
      localLanguage !== ""
    ) {
      i18n.changeLanguage(localLanguage);
      Settings.defaultLocale = localLanguage.substr(0, 2);
    } else {
      var defaultLanguage = getGlobalSetting("language", "systemLanguage");
      if (!defaultLanguage) {
        defaultLanguage = "de-DE";
      }
      i18n.changeLanguage(defaultLanguage);
      Settings.defaultLocale = defaultLanguage.substr(0, 2);
      localStorage.setItem("lang", defaultLanguage);
    }
    // const token = localStorage.getItem("jwt");
    if (loggedUserId !== "") {
      const userid = loggedUserId;
      // Set logged user
      props.setLoggedUser(userid);

      // Create personal settings if needed
      props.createPersonalSettings(userid);
      // Get company data
      props.fetchCompanyLocations();
      // Get company departments
      props.fetchDepartmentsMinimal();
      // Get settings of user
      props.fetchPersonalSettings(userid);
      props.fetchPersonalDisplaySettings(userid);
      props.fetchStandardDisplaySettings();
      // Get tracked time of logged person
      props.fetchTimes({
        userId: userid,
        month: today.month,
        year: today.year,
      });
      // Get unpublished days
      props.fetchUnpublishedDays(userid);
      // Reset state
      store.dispatch({ type: "SET_EDIT_MODE", payload: false });
      store.dispatch({ type: "SET_SELECTED_WORKDAYS", payload: 0 });
      store.dispatch({ type: "SET_SELECTED_CALENDARDAYS", payload: 0 });
      store.dispatch({ type: "SET_SELECTED_DAYS", payload: [] });
      store.dispatch({ type: "SET_PERSON_ID", payload: -1 });
      store.dispatch({ type: "SET_SELECTED_TIME_FORM_ID", payload: -1 });
      // Stats are getting fetched all over the place. There is literally no need to have an interval for this.
      // TODO: Remove.
      // WR: updateData() is used to set the alive-time and check for reminders
      timeRef.current = setInterval(() => {
        updateData();
      }, 30000);

      if (history.goBack.length === 0 && history.location.pathname === "/") {
        const startPage = localStorage.getItem("startPage");
        const lastPage = localStorage.getItem("lastPage") || "";
        if (lastPage.length > 0 && lastPage !== "undefined") {
          history.push("/" + lastPage);
        } else if (startPage?.length > 0) {
          if (
            startPage === "MonthCalendar" &&
            hasPermission("showMonthCalendar")
          ) {
            history.push("/MonthCalendar");
          } else {
            history.push("/" + startPage);
          }
        } else {
          history.push("/");
          localStorage.setItem("startPage", "");
        }
      }
    } else {
      history.push("/login");
    }
    setDisableMessages(
      parseInt(getGlobalSetting("system", "useMessageModule")) !== 1
    );
    return () => {
      clearInterval(timeRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUserId]);

  return (
    <>
      <CssBaseline />
      {loggedUser.id && (
        <Drawer
          variant="permanent"
          sx={{
            flexShrink: 0,
            whiteSpace: "nowrap",
            border: "none",
            backgroundColor: "background.drawer",
            overflowX: "hidden",
            ...(open
              ? {
                  width: drawerWidth,
                  transition: (theme) =>
                    theme.transitions.create("width", {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                }
              : {
                  width: "73px",
                  transition: (theme) =>
                    theme.transitions.create("width", {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    }),
                }),
            "& .MuiPaper-root": {
              border: "none",
              backgroundColor: "background.drawer",
              ...(open
                ? {
                    width: drawerWidth,
                    transition: (theme) =>
                      theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                      }),
                  }
                : {
                    transition: (theme) =>
                      theme.transitions.create("width", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                      }),
                    overflowX: "hidden",
                    width: "73px",
                  }),
            },
          }}
        >
          <Box
            sx={{
              border: "none",
              minHeight: "50px !important",
              height: appBarHeight,
              backgroundColor: "somura",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {open ? (
              <Box
                component="img"
                sx={{
                  height: "1.8rem",
                }}
                alt="Logo"
                src="/images/Somura-logo-default_wht.svg"
              />
            ) : (
              <Box
                component="img"
                sx={{
                  height: "1.8rem",
                }}
                alt="Logo"
                src="/images/Somura-icon_wht_512.svg"
              />
            )}
          </Box>
          <List>
            {hasPermission("showDashboard") && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="Dashboard"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  to={"/"}
                  key="Dashboard"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip title="Dashboard" placement="right">
                    <ListItemIcon>
                      <DashboardTwoToneIcon
                        sx={{
                          fontSize: "2rem",
                        }}
                      />
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText primary="Dashboard" />
                </ListItemButton>
              </ListItem>
            )}
            {hasPermission("showMonthCalendar") && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="MonthCalendar"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  onClick={menuItemClick("MonthCalendar")}
                  to={"/MonthCalendar"}
                  key="Monatskalender"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip title={t("Month calendar")} placement="right">
                    <ListItemIcon>
                      <MonthCalIcon
                        sx={{
                          fontSize: "2rem",
                        }}
                      />
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText primary={t("Month calendar")} />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
              <ListItemButton
                name="YearCalendar"
                sx={{
                  width: "4rem",
                  height: "4rem",
                  margin: "0.3rem",
                  borderRadius: "6px",
                }}
                component={NavLink}
                exact
                onClick={menuItemClick("YearCalendar")}
                to={"/YearCalendar"}
                key="Jahreskalender"
                activeClassName="selectedItem"
              >
                <SomuraTooltip title={t("Year calendar")} placement="right">
                  <ListItemIcon>
                    <DateRangeIcon
                      sx={{
                        fontSize: "2rem",
                      }}
                    />
                  </ListItemIcon>
                </SomuraTooltip>
                <ListItemText primary={t("Year calendar")} />
              </ListItemButton>
            </ListItem>
            {/* {hasPermission("showAbsenceCalendar") && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="AbsenceCalendar"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  onClick={menuItemClick("AbsenceCalendar")}
                  to={"/AbsenceCalendar"}
                  key="Abwesenheitskalender"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip
                    title={t("Absence calendar")}
                    placement="right"
                  >
                    <ListItemIcon>
                      <EventBusyIcon
                        sx={{
                          fontSize: "2rem",
                        }}
                      />
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText primary={t("Absence calendar")} />
                </ListItemButton>
              </ListItem>
            )} */}
            {hasPermission("showPersons") && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="Persons"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  onClick={menuItemClick("Persons")}
                  to={"/Persons"}
                  key="Mitarbeiter"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip title={t("Persons")} placement="right">
                    <ListItemIcon>
                      <PersonsIcon
                        sx={{
                          fontSize: "2rem",
                        }}
                      />
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText primary={t("Persons")} />
                </ListItemButton>
              </ListItem>
            )}
            {((useTimeTracking &&
              !loggedUser.noTimeTracking &&
              !loggedUser.noWebTimeTracking) ||
              loggedUser.isDeveloper) && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="Times"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  onClick={menuItemClick("TimeTracking")}
                  to={"/TimeTracking"}
                  key="Zeiterfassung"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip
                    title={t(
                      hasPermission("administerTimeTracking")
                        ? "Time tracking"
                        : "Personal worktimes"
                    )}
                    placement="right"
                  >
                    <ListItemIcon>
                      {hasPermission("administerTimeTracking") ? (
                        <TimeFaultyBadge
                          sx={{
                            fontSize: "2rem",
                          }}
                        />
                      ) : (
                        <TimeTrackIcon
                          sx={{
                            fontSize: "2rem",
                          }}
                        />
                      )}
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText
                    primary={t(
                      hasPermission("administerTimeTracking")
                        ? "Time tracking"
                        : "Personal worktimes"
                    )}
                  />
                </ListItemButton>
              </ListItem>
            )}
            {!disableMessages && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="Messages"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  onClick={menuItemClick("Messages")}
                  to={"/Messages"}
                  key="Nachrichten"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip
                    title={
                      disableMessages
                        ? t("Messages are disabled")
                        : t("Messages")
                    }
                    placement="right"
                  >
                    <ListItemIcon>
                      <NewsBadge
                        sx={{
                          fontSize: "2rem",
                        }}
                      />
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText primary={t("Messages")} />
                </ListItemButton>
              </ListItem>
            )}
            {useArticles && hasPermission("showArticles") && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="Articles"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  onClick={menuItemClick("Articles")}
                  to={"/Articles"}
                  key="Artikel"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip
                    title={
                         t("Articles")
                    }
                    placement="right"
                  >
                    <ListItemIcon>
                      <DevicesIcon
                      />
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText primary={t("Articles")} />
                </ListItemButton>
              </ListItem>
            )}
            {loggedUser.isDeveloper && (
              <ListItem sx={{ width: "18rem", marginLeft: "-1.4rem" }}>
                <ListItemButton
                  name="Projects"
                  sx={{
                    width: "4rem",
                    height: "4rem",
                    margin: "0.3rem",
                    borderRadius: "6px",
                  }}
                  component={NavLink}
                  exact
                  onClick={menuItemClick("Projects")}
                  to={"/Projects"}
                  key="Projects"
                  activeClassName="selectedItem"
                >
                  <SomuraTooltip title={t("Projects")} placement="right">
                    <ListItemIcon>
                      <ProjectsIcon />
                    </ListItemIcon>
                  </SomuraTooltip>
                  <ListItemText primary={t("Projects")} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Drawer>
      )}

      {loggedUser.id && (
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "somura",
            color: "white",
            display: "grid",
            gridTemplateRows: "1fr",
            gridTemplateColumns: "2rem 2rem 1fr 1fr 4rem 4rem 4rem 4rem",
            height: appBarHeight,
            marginLeft: "73px",
            width: `calc(100% - 73px)`,
            zIndex: (theme) => theme.zIndex.drawer + 1,
            transition: (theme) =>
              theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            ...(open
              ? {
                  marginLeft: drawerWidth,
                  width: `calc(100% - ${drawerWidth}px)`,
                }
              : {}),
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            sx={{
              width: "1rem",
              marginLeft: "0.1rem",
              gridRow: 1,
              gridColumn: 1,
            }}
            size="large"
          >
            {open ? (
              <SomuraTooltip title={t("Shrink sidebar")} placement="right">
                <ChevronLeftIcon
                  sx={{
                    width: "4rem",
                    gridRow: "1",
                  }}
                />
              </SomuraTooltip>
            ) : (
              <SomuraTooltip title={t("Expand sidebar")} placement="right">
                <ChevronRightIcon
                  sx={{
                    width: "4rem",
                    gridRow: "1",
                  }}
                />
              </SomuraTooltip>
            )}
          </IconButton>
          <SomuraTooltip title={t("Reload")} placement="right">
            <IconButton
              edge="start"
              sx={{
                width: "1rem",
                marginLeft: "0.1rem",
                gridRow: 1,
                gridColumn: 2,
              }}
              size="large"
              onClick={handleReloadClick}
            >
              <ReplayIcon
                sx={{
                  width: "4rem",
                  gridRow: "1",
                }}
              />
            </IconButton>
          </SomuraTooltip>
          {useTimeTracking &&
            !loggedUser.noTimeTracking &&
            !loggedUser.noWebTimeTracking && <ShowTime />}
          {hasPermission("seeAllCompanyLocations") &&
            props.locations.length > 1 && (
              <Box
                sx={{
                  gridColumn: 4,
                  borderRadius: "0.5rem",
                  boxShadow: "inset 3px 3px 3px 1px rgba(0, 0, 0, 0.4)",
                  alignSelf: "center",
                  justifySelf: "center",
                }}
              >
                <LocationSelection />
              </Box>
            )}
          <Box
            sx={{
              width: "16rem",
              gridColumn: "-2 / -5",
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            {todayReminders > 0 && (
              <SomuraTooltip
                title={
                  `${todayReminders} ` +
                  (todayReminders === 1 ? t("appointment") : t("appointments"))
                }
                placement="left"
              >
                <IconButton
                  sx={{
                    width: "4rem",
                    gridRow: "1",
                  }}
                  name="Reminders"
                  component={NavLink}
                  onClick={menuItemClick("Reminders")}
                  to={"/Reminders"}
                  edge="end"
                  aria-label="reminders"
                  aria-controls="usermenu"
                  aria-haspopup="true"
                  color="inherit"
                  size="large"
                >
                  <RemindersBadge count={todayReminders} />
                </IconButton>
              </SomuraTooltip>
            )}
            {props.unpublishedDays.length > 0 && (
              <SomuraTooltip
                title={`${numUnpublished} ` + t("unpublished days")}
                placement="left"
              >
                <IconButton
                  sx={{
                    width: "4rem",
                    gridRow: "1",
                  }}
                  edge="end"
                  aria-label="unpublished days"
                  aria-controls="usermenu"
                  aria-haspopup="true"
                  onClick={handleUDOpen}
                  color="inherit"
                  size="large"
                >
                  <UnpublishedDaysBadge closeUnpublishedDays={handleUDClose} />
                </IconButton>
              </SomuraTooltip>
            )}

            {hasPermission("showSettingsMenu") && (
              <SettingsMenu
                sx={{
                  width: "4rem",
                  gridRow: "1",
                }}
              />
            )}
            {multilingual && (
              <LanguageMenu
                sx={{
                  width: "4rem",
                  gridRow: "1",
                }}
              />
            )}
            <UserMenu
              sx={{
                width: "4rem",
                gridRow: "1",
              }}
            />
          </Box>
        </AppBar>
      )}
      <Box
        sx={{
          maxWidth: maxClientWidth > 0 ? maxClientWidth + "vw" : "100%",
          margin: clientPosition === 0 ? "54px auto 0px 0px" : clientPosition === 2 ? "54px 0px 0px auto" : "54px auto",
        }}
      >
        {props.children}
      </Box>
      <Snackbar
        open={
          props.dataError &&
          props.error.indexOf("Unexpected end of JSON input") === -1
        } // error is supressed because it could not be located :(
        autoHideDuration={4000}
        onClose={handleSnackClose}
      >
        <SomuraAlert
          severity="error"
          title={
            props.error ? t("Error") + ": <<" + props.error + ">>" : t("Error")
          }
          message={
            props.error
              ? props.error === "Failed to fetch"
                ? t("Data could not be loaded!")
                : t("Data was NOT processed!")
              : t("Changes were NOT processed!") + ": " + props.error
          }
        />
      </Snackbar>
      <Snackbar
        open={props.dataSuccess}
        autoHideDuration={4000}
        onClose={handleSnackClose}
      >
        <SomuraAlert
          severity="success"
          message={t("Changes were successfully saved.")}
        />
      </Snackbar>
      <Snackbar
        open={props.actionSuccess}
        autoHideDuration={4000}
        onClose={handleSnackClose}
      >
        <SomuraAlert
          severity="success"
          message={t("The action was successfully completed.")}
        />
      </Snackbar>
      {openUnpublishedDays === true && (
        <UnpublishedDays
          closeUnpublishedDays={() => setUnpublishedDays(false)}
        />
      )}
      {openAlert && (
        <RemindersAlert
          reminders={alertReminders}
          handleCloseDialog={handleCloseAlert}
        />
      )}
      {props.showReminderDate === "Menu" && <RemindersDialog />}
    </>
  );
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainMenu)
);
