import {
  FETCH_BUSINESSTRIPS_SUCCESS,
  FETCH_BUSINESSTRIPS_USER_SUCCESS,
  CREATE_BUSINESSTRIP,
  UPDATE_BUSINESSTRIP,
  DELETE_BUSINESSTRIP,
  SET_BUSINESSTRIP_ID
} from "actions/types";

import {
  businessTripsRead,
  businessTripsUserRead,
  businessTripCreate,
  businessTripUpdate,
  businessTripDelete,
} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";


const fetchBusinessTripsSuccess = (values) => {
  return {
    type: FETCH_BUSINESSTRIPS_SUCCESS,
    payload: values,
  };
};

export const fetchBusinessTrips = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(businessTripsRead());
      await dispatch(fetchBusinessTripsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const fetchBusinessTripsUserSuccess = (values) => {
  return {
    type: FETCH_BUSINESSTRIPS_USER_SUCCESS,
    payload: values,
  };
};

export const fetchBusinessTripsUser = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(businessTripsUserRead(values));
      await dispatch(fetchBusinessTripsUserSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createBusinessTripData = (values) => {
  return {
    type: CREATE_BUSINESSTRIP,
    payload: values,
  };
};

export const createBusinessTrip = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(businessTripCreate(values));
      await dispatch(createBusinessTripData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateBusinessTripData = (values) => {
  return {
    type: UPDATE_BUSINESSTRIP,
    payload: values,
  };
};

export const updateBusinessTrip = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(businessTripUpdate(values));
      await dispatch(updateBusinessTripData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteBusinessTripData = (values) => {
  return {
    type: DELETE_BUSINESSTRIP,
    payload: values,
  };
};

export const deleteBusinessTrip = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(businessTripDelete(values));
      await dispatch(deleteBusinessTripData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setBusinessTripId = (values) => {
  return {
    type: SET_BUSINESSTRIP_ID,
    payload: values,
  };
};