import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createLogger } from "redux-logger";

import { thunk } from "redux-thunk";
import promise from "redux-promise-middleware";

// import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import authReducer from "reducers/auth";
import generalReducer from "reducers/general";
import todayReducer from "reducers/today";
import selectionsReducer from "reducers/selections";
import settingsReducer from "reducers/settings";
import countriesReducer from "reducers/countries";
import companiesReducer from "reducers/companies";
import industriesReducer from "reducers/industries";
import dashboardReducer from "reducers/dashboard";
import personsReducer from "reducers/persons";
import articlesReducer from "reducers/articles";
import yearReducer from "reducers/year";
import monthReducer from "reducers/month";
import unpublishedDaysReducer from "reducers/unpublishedDays";
import offDayTypesReducer from "reducers/offDayTypes";
import offDaysReducer from "reducers/offDays";
import yearCalendarReducer from "reducers/yearCalendar";
import companyReducer from "reducers/company";
import specialDaysReducer from "reducers/specialDays";
import groupTypesReducer from "reducers/groupTypes";
import departmentReducer from "reducers/departments";
import loggedUserReducer from "reducers/loggedUser";
import messagesReducer from "reducers/messages";
import messageFoldersReducer from "reducers/messageFolders";
import remindersReducer from "reducers/reminders";
import remindercategoriesReducer from "reducers/remindercategories";
import businessTripsReducer from "reducers/businesstrips"
import tripDestinationsReducer from "reducers/tripdestinations"
import remarksReducer from "reducers/remarks";
import timesReducer from "reducers/times";
import recurringAbsencesReducer from "reducers/recurringabsences";
import excludedRecurringAbsencesReducer from "reducers/excludedrecurringabsences";
import dailyTimePlansReducer from "reducers/dailytimeplans";
import weeklyTimePlansReducer from "reducers/weeklytimeplans";
import timeclocksReducer from "reducers/timeclocks";
import exitReasonsReducer from "reducers/exitreasons";
import statsReducer from "../reducers/stats";
import logReducer from "../reducers/log";
import securityReducer from "../reducers/security";
import changeLogReducer from "../reducers/changeLog";
import projectsReducer from "../reducers/projects";
import documentsReducer from "../reducers/documents";

const loggerMiddleware = createLogger();
// eslint-disable-next-line
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // eslint-disable-next-line
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;

const appReducer = combineReducers({
  articles: articlesReducer,
  auth: authReducer,
  businesstrips: businessTripsReducer,
  changeLog: changeLogReducer,
  companies: companiesReducer,
  company: companyReducer,
  countries: countriesReducer,
  dailytimeplans: dailyTimePlansReducer,
  dashboard: dashboardReducer,
  departments: departmentReducer,
  documents: documentsReducer,
  excludedrecurringabsences: excludedRecurringAbsencesReducer,
  general: generalReducer,
  grouptypes: groupTypesReducer,
  industries: industriesReducer,
  loggeduser: loggedUserReducer,
  log: logReducer,
  messagefolders: messageFoldersReducer,
  messages: messagesReducer,
  month: monthReducer,
  offdays: offDaysReducer,
  offdaytypes: offDayTypesReducer,
  persons: personsReducer,
  projects: projectsReducer,
  recurringabsences: recurringAbsencesReducer,
  remarks: remarksReducer,
  reminders: remindersReducer,
  remindercategories: remindercategoriesReducer,
  selections: selectionsReducer,
  security: securityReducer,
  settings: settingsReducer,
  specialdays: specialDaysReducer,
  stats: statsReducer,
  times: timesReducer,
  today: todayReducer,
  tripdestinations: tripDestinationsReducer,
  unpublisheddays: unpublishedDaysReducer,
  weeklytimeplans: weeklyTimePlansReducer,
  timeclocks: timeclocksReducer,
  exitreasons: exitReasonsReducer,
  year: yearReducer,
  yearcalendar: yearCalendarReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(promise, thunk, loggerMiddleware))
);

export default store ;
