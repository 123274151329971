import {
  FETCH_DESTINATIONS_SUCCESS,
  CREATE_DESTINATION,
  UPDATE_DESTINATION,
  DELETE_DESTINATION,
  SET_DESTINATION_ID
} from "actions/types";

import {
  destinationsRead,
  destinationCreate,
  destinationUpdate,
  destinationDelete,
} from "../api";
import {
  dataFailure,
  dataSuccess,
} from "./general";


const fetchDestinationsSuccess = (values) => {
  return {
    type: FETCH_DESTINATIONS_SUCCESS,
    payload: values,
  };
};

export const fetchDestinations = () => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(destinationsRead());
      await dispatch(fetchDestinationsSuccess(JSONData));
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const createDestinationData = (values) => {
  return {
    type: CREATE_DESTINATION,
    payload: values,
  };
};

export const createDestination = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(destinationCreate(values));
      await dispatch(createDestinationData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const updateDestinationData = (values) => {
  return {
    type: UPDATE_DESTINATION,
    payload: values,
  };
};

export const updateDestination = (values) => {
  return async (dispatch) => {
    try {
      const JSONData = await dispatch(destinationUpdate(values));
      await dispatch(updateDestinationData(JSONData));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

const deleteDestinationData = (values) => {
  return {
    type: DELETE_DESTINATION,
    payload: values,
  };
};

export const deleteDestination = (values) => {
  return async (dispatch) => {
    try {
      await dispatch(destinationDelete(values));
      await dispatch(deleteDestinationData(values));
      await dispatch(dataSuccess());
    } catch (err) {
      await dispatch(dataFailure(err.message));
    }
  };
};

export const setDestinationId = (values) => {
  return {
    type: SET_DESTINATION_ID,
    payload: values,
  };
};