import {
  FETCH_DESTINATIONS_SUCCESS,
  CREATE_DESTINATION,
  UPDATE_DESTINATION,
  DELETE_DESTINATION,
  SET_DESTINATION_ID,
  OPEN_DESTINATION_DIALOG
} from "actions/types";
import { produce } from "immer";

const initialState = {
  TripDestinations: [],
  tripDestinationId: -1,
  showDestinationDialog: false,
  error: "",
};

const tripDestinationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    var key;
    switch (action.type) {
      case FETCH_DESTINATIONS_SUCCESS:
        draft.TripDestinations = action.payload;
        draft.error = "";
        break;
      case CREATE_DESTINATION:
        draft.TripDestinations = [action.payload, ...draft.TripDestinations];
        draft.tripDestinationId = action.payload.id
        draft.error = "";
        break;
      case UPDATE_DESTINATION:
        key = draft.TripDestinations.findIndex(
          (TD) => TD.id === action.payload.id
        );
        draft.TripDestinations[key] = action.payload;
        draft.tripDestinationId = -1;
        draft.error = "";
        break;
      case DELETE_DESTINATION:
        key = draft.TripDestinations.findIndex(
          (TD) => TD.id === action.payload.id
        );
        draft.TripDestinations.splice(key, 1);
        draft.tripDestinationId = -1;
        draft.error = "";
        break;
      case SET_DESTINATION_ID:
        draft.tripDestinationId = action.payload;
        break;
      case OPEN_DESTINATION_DIALOG:
        draft.showDestinationDialog = action.payload;
        break;
      default:
        return state;
    }
  });

export default tripDestinationsReducer;
